import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import TagComponent from '../../atoms/Tag'
import Icon from '../../atoms/Icon'
import ScoreComponent from '../../atoms/Score'
import ContentCard from '../ContentCard'
import { CardVariant } from '../ContentCard/styled'

export const Card = styled(ContentCard)<{ $alwaysShowScore?: boolean }>`
  @media ${mq(sizes.tablet)} {
    ${({ variant }) =>
      variant === CardVariant.Small &&
      css`
        max-width: 22.8rem;
        flex-direction: column;
        ${Tags} {
          display: none;
        }
        /* ${RecipeInfosSeparator} {
          display: none;
        } */
        /* ${Score} {
          display: none;
        } */
        ${BrandName} {
          display: flex;
          align-self: flex-start;
        }
        ${RecipeInfos} {
          align-items: center;
          width: 100%;
        }
      `}

    ${({ variant, $alwaysShowScore }) =>
      variant === CardVariant.Tiny &&
      css`
        flex-direction: row-reverse;
        max-width: initial;
        ${Tags} {
          display: none;
        }
        ${RecipeInfosSeparator} {
          display: ${$alwaysShowScore ? 'flex' : 'none'}};
        }
        ${Score} {
          max-height: 2rem;
          display: ${$alwaysShowScore ? 'flex' : 'none'}};
        }
        ${BrandName} {
          display: flex;
          align-self: flex-start;
        }
        ${RecipeInfos} {
          align-items: center;
          width: 100%;
        }
      `}
  }
`

export const Spacer = styled.div`
  flex-grow: 1;
`

export const Tags = styled.div`
  display: none;
  @media ${mq(sizes.tablet)} {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.3rem;
    padding-bottom: 1.6rem;
  }
`
export const Tag = styled(TagComponent)``

export const RecipeInfos = styled.div`
  display: flex;
  align-items: center;
`

export const RecipeInfosSeparator = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.colors.silver};
  width: 1px;
  height: 3.2rem;
  margin: 0 1rem;
`

export const Score = styled(ScoreComponent)`
  display: flex;
  height: 3.2rem;
  margin-left: 0.5rem;
`
export const Duration = styled.div`
  ${({ theme }) => theme.textStyles.textSmallStrong};
  color: ${({ theme }) => theme.colors.ruby};
  display: flex;
`
export const DurationIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.ruby};
  width: 2rem;
  height: 2rem;
  margin-right: 0.4rem;
`

export const BrandName = styled.div`
  ${({ theme }) => theme.textStyles.textXSmallStrong};
  color: ${({ theme }) => theme.colors.bismark};
  padding-top: 0.5rem;
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`

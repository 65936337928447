import styled from 'styled-components'

import Icon from '../../atoms/Icon'

export enum ScoreSize {
  small = 'small',
  large = 'large',
}

export const Score = styled.div<{ $size?: ScoreSize }>`
  height: ${({ $size }) => ($size === ScoreSize.large ? '7rem' : '3.2rem')};
`

export const ScoreIcon = styled(Icon)`
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  height: 100%;
  flex-shrink: 0;
`

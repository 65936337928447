import styled, { css } from 'styled-components'
import React from 'react'

import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import Link, { LinkProps } from '../../atoms/Link'
import Icon from '../../atoms/Icon'

export enum CardVariant {
  Default = 'Default',
  Small = 'Small',
  Tiny = 'Tiny',
  Cheese = 'Cheese',
}

type mainStylesProps = { $variant?: CardVariant }
export type MainDivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  mainStylesProps
export type MainLinkProps = LinkProps & mainStylesProps

const mainStyles = css<mainStylesProps>`
  border-radius: 2rem;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: center;

  ${({ $variant }) =>
    $variant === CardVariant.Small &&
    css`
      ${BackgroundImage} {
        width: 13rem;
        height: initial;
        min-height: 13rem;
      }
    `}

  @media ${mq(sizes.tablet)} {
    flex-direction: column;
    transition: transform 0.3s, box-shadow 0.3s;
    &:hover,
    &:focus-visible {
      box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.15);
      transform: translateY(-0.2rem);
    }
    flex-grow: 1;

    ${({ $variant }) =>
      $variant === CardVariant.Small &&
      css`
        max-width: 22.8rem;
        flex-direction: column;
        ${BackgroundImage} {
          width: 100%;
          height: 14rem;
        }
        ${Bezel} {
          display: none;
        }
        ${LogoContainer} {
          display: none;
        }
        ${LogoSpacer} {
          display: none;
        }
        ${Card} {
          text-align: left;
        }
        ${Content} {
          padding: 1rem 1.5rem 1.5rem;
        }
        ${Title} {
          ${({ theme }) => theme.textStyles.textSmallStrong};
          text-align: left;
          flex-grow: 1;
          margin-bottom: 1rem;
          max-height: ${1.4 * 1.4 * 2}rem;
        }
      `}

    ${({ $variant }) =>
      $variant === CardVariant.Tiny &&
      css`
        max-width: initial;
        flex-direction: row-reverse;
        ${BackgroundImage} {
          width: 13rem;
          aspect-ratio: 1 / 1;
          height: auto;
        }
        ${Bezel} {
          display: none;
        }
        ${LogoContainer} {
          display: none;
        }
        ${LogoSpacer} {
          display: none;
        }
        ${Card} {
          text-align: left;
          border-radius: 0;
        }
        ${Content} {
          padding: 1rem 1.5rem 1.5rem;
        }
        ${Title} {
          ${({ theme }) => theme.textStyles.textSmallStrong};
          text-align: left;
          margin-bottom: 1rem;
          max-height: ${1.4 * 1.4 * 2}rem;
        }
      `}
  }
`
export const MainLink = styled(Link)<MainLinkProps>`
  ${mainStyles}
`
export const Main = styled.div<MainDivProps>`
  ${mainStyles}
`

export const BackgroundImage = styled(Picture)<{ $variant?: CardVariant }>`
  width: 14rem;
  flex-shrink: 0;
  height: 14rem;
  background: rgba(0, 0, 0, 0.1);
  &,
  & img {
    display: flex;
  }
  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  @media ${mq(sizes.tablet)} {
    height: 21rem;
    width: 100%;

    ${({ $variant }) =>
      $variant === CardVariant.Cheese &&
      css`
        height: 28rem;
      `}
  }
`

export const DesktopBackgroundImage = styled(BackgroundImage)`
  display: none;
  @media ${mq(sizes.tablet)} {
    display: block;
  }
`
export const MobileBackgroundImage = styled(BackgroundImage)`
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`

export const Badges = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  @media ${mq(sizes.tablet)} {
    top: 1rem;
    right: 1rem;
  }
`

export const Badge = styled(Icon)`
  width: 3rem;
  height: 3rem;
  padding: 0.2rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 50%;
  @media ${mq(sizes.tablet)} {
    width: 5rem;
    height: 5rem;
  }
`

export const Card = styled.article`
  position: relative;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media ${mq(sizes.tablet)} {
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    flex-grow: 1;
  }
`

export const Bezel = styled.svg`
  display: none;
  @media ${mq(sizes.tablet)} {
    position: absolute;
    top: -0.9rem;
    width: 100%;
    height: 1rem;
    & path {
      fill: ${({ theme }) => theme.colors.pureWhite};
    }
    display: flex;
  }
`

export const LogoContainer = styled.div<{ $variant?: CardVariant }>`
  display: none;

  @media ${mq(sizes.tablet)} {
    display: flex;
    position: absolute;
    width: 18rem;
    height: 18rem;
    top: -5.8rem;
    padding-top: 1.6rem;
    background-color: ${({ theme }) => theme.colors.pureWhite};
    border-radius: 100%;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: center;

    ${({ $variant }) =>
      $variant === CardVariant.Cheese &&
      css`
        width: 8rem;
        height: 8rem;
        border: 1px solid ${({ theme }) => theme.colors.pattensBlue};
        padding-top: 0;

        & picture {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        & img {
          width: 6.3rem;
          height: 6.3rem;
        }
      `}
  }
`

export const LogoSpacer = styled.div<{ $variant?: CardVariant }>`
  display: none;

  @media ${mq(sizes.tablet)} {
    display: flex;
    width: 100%;
    height: 4rem;

    ${({ $variant }) =>
      $variant === CardVariant.Cheese &&
      css`
        height: 2rem;
      `}
  }
`

export const Content = styled.div<{ $variant?: CardVariant }>`
  --margin-top__title: 0;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
  flex-grow: 1;
  justify-content: space-between;

  @media ${mq(sizes.tablet)} {
    align-items: center;
    padding: ${({ $variant }) =>
      $variant === CardVariant.Cheese && '1.5rem 1.5rem 3rem 1.5rem'};
    ${({ $variant }) =>
      $variant !== CardVariant.Tiny &&
      css`
        --margin-top__title: auto;
      `}
  }
`

export const Logo = styled(Picture)`
  width: 8rem;
  height: 8rem;
  & img {
    object-fit: contain;
    height: auto;
  }
`

export const Title = styled.div`
  ${({ theme }) => theme.textStyles.textSmallStrong};
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: left;
  margin: 0;
  width: 100%;
  flex-grow: 1;
  margin-bottom: 1rem;
  overflow: hidden;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH4};
    text-align: center;
    margin-top: var(--margin-top__title);
    margin-bottom: 3rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: ${2 * 1.2 * 2}rem;
  }
`

import React, { FC } from 'react'

import { PictureProps } from '../../atoms/Picture'
import { LinkProps } from '../../atoms/Link'
import { IconProps } from '../../atoms/Icon'

import * as SC from './styled'
import { CardVariant, MainDivProps, MainLinkProps } from './styled'

export enum CardContentType {
  Article = 'Article',
  Folder = 'Folder',
  Recipe = 'Recipe',
  Cheese = 'Cheese',
  Plate = 'Plate',
}

export type ContentCardProps = MinimalSeoBlock & {
  className?: string
  imageProps: PictureProps
  mobileImageProps?: PictureProps
  brandLogoProps?: PictureProps
  brandName?: string
  title?: string
  linkProps?: LinkProps
  variant?: CardVariant
  children?: React.ReactNode
  type?: CardContentType
  badges?: IconProps[]
}

const ContentCard: FC<ContentCardProps> = ({
  className,
  htmlTag,
  imageProps,
  mobileImageProps,
  brandLogoProps,
  title,
  htmlTitleTag,
  linkProps,
  variant,
  children,
  type,
  badges,
}) => {
  const MainComponent: FC<MainLinkProps | MainDivProps> = linkProps
    ? SC.MainLink
    : SC.Main

  return (
    <MainComponent {...linkProps} className={className} $variant={variant}>
      {!mobileImageProps && (
        <SC.BackgroundImage {...imageProps} $variant={variant} />
      )}
      {mobileImageProps && (
        <>
          <SC.MobileBackgroundImage {...mobileImageProps} $variant={variant} />
          <SC.DesktopBackgroundImage {...imageProps} $variant={variant} />
        </>
      )}
      {badges && (
        <SC.Badges>
          {badges?.map((badge, i) => (
            <SC.Badge {...badge} key={i} />
          ))}
        </SC.Badges>
      )}
      <SC.Card {...linkProps} as={htmlTag}>
        <SC.Bezel
          viewBox="0 0 335 11"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path d="M0 0.375V10.375H168.303C99.7883 10.3705 38.8536 6.45373 0 0.375ZM168.537 10.375H335V0.658797C296.078 6.57536 235.98 10.3706 168.537 10.375Z" />
        </SC.Bezel>
        {brandLogoProps && (
          <>
            <SC.LogoContainer $variant={variant}>
              <SC.Logo {...brandLogoProps} width={80} height={80} />
            </SC.LogoContainer>
            <SC.LogoSpacer $variant={variant} />
          </>
        )}
        {type === CardContentType.Recipe && !brandLogoProps && (
          <SC.LogoSpacer $variant={variant} />
        )}
        <SC.Content $variant={variant}>
          {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
          {children}
        </SC.Content>
      </SC.Card>
    </MainComponent>
  )
}

export default ContentCard

import styled, { css } from 'styled-components'

import Link from '../Link'

export const Tag = styled(Link)<{
  $active?: boolean
  $disabled?: boolean
  $variant?: TagVariant
}>`
  ${({ theme }) => theme.textStyles.titleH8}
  padding: 0.3rem 0.5rem 0.1rem;

  border-radius: 0.4rem;
  border-width: 1px;
  border-style: solid;

  transition: border-color 0.3s, background-color 0.3s, color 0.3s;

  border-color: ${({ theme, $active }) =>
    $active ? theme.colors.arapawa : theme.colors.bismark};
  background-color: ${({ $active, theme, $variant }) => {
    if ($active && (!$variant || $variant === TagVariant.cloud)) {
      return theme.colors.arapawa
    }
    if ($active && (!$variant || $variant === TagVariant.positive)) {
      return theme.colors.arapawa
    }

    return $variant === TagVariant.cloud
      ? theme.colors.pureWhite
      : $variant === TagVariant.primary
      ? theme.colors.linen
      : $variant === TagVariant.meal
      ? theme.colors.chablis
      : $variant === TagVariant.diet
      ? theme.colors.pattensBlue
      : $variant === TagVariant.positive
      ? theme.colors.offGreen
      : $variant === TagVariant.article
      ? theme.colors.oldLace
      : $variant === TagVariant.planche
      ? theme.colors.oldLace
      : $variant === TagVariant.folder
      ? theme.colors.melon
      : $variant === TagVariant.brand
      ? theme.colors.linen
      : theme.colors.pureWhite
  }};
  color: ${({ theme, $active, $variant }) => {
    if ($active && (!$variant || $variant === TagVariant.cloud))
      return theme.colors.pureWhite
    if ($active && (!$variant || $variant === TagVariant.positive)) {
      return theme.colors.pureWhite
    }
    return theme.colors.arapawa
  }};

  ${({ $disabled }) =>
    !$disabled &&
    css<{
      $active?: boolean
      $variant?: TagVariant
    }>`
      &:hover:not(:disabled):not(span) {
        border-color: ${({ theme }) => theme.colors.arapawa};
        color: ${({ theme, $variant }) =>
          !$variant || $variant === TagVariant.cloud
            ? theme.colors.pureWhite
            : theme.colors.arapawa};
        background-color: ${({ theme, $variant }) =>
          !$variant || $variant === TagVariant.cloud
            ? theme.colors.arapawa
            : theme.colors.pureWhite};
      }
    `}
`

export enum TagVariant {
  cloud = 'cloud',
  primary = 'primary',
  meal = 'meal',
  diet = 'diet',
  positive = 'positive',
  article = 'article',
  folder = 'folder',
  brand = 'brand',
  planche = 'planche',
}
